const menu = () => {


    const menuLinks = document.querySelectorAll('.acordion'),
          menuBg = document.querySelector('.menu-bg');

    // menuLinks.forEach(item => {
    //     item.addEventListener('click', (e) => {
    //        item.querySelectorAll('a').forEach(link => {
    //             console.log(link);
    //             if(e.target != link) {
    //                 item.querySelector('.header-acordion').classList.toggle('active');
    //             }
    //        })

    //     //    item.querySelector('.header-acordion').classList.toggle('active');

    //        console.log(e.target)
    //     })
    // })

    menuLinks.forEach(item => {
        item.addEventListener('click', (e) => {
            menuLinks.forEach(el => {
                el.querySelector('.header-acordion').classList.remove('active');
            })

            item.querySelector('.header-acordion').classList.add('active');
        })
    })

    console.log(menuLinks)

    const gum = document.querySelector('.header-gum'),
          close = document.querySelector('.close'),
          nav = document.querySelector('.header-nav');

    gum.addEventListener('click', () => {
        nav.classList.remove('no-active');
        document.body.style.overflow = 'hidden';
        menuBg.classList.add('active');
    })

    close.addEventListener('click', () => {
        nav.classList.add('no-active');
        document.body.style.overflow = '';
        menuBg.classList.remove('active');
    })


}

export default menu;