// import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

const slider = () => {

    $('.slider').slick({
        // arrows: false,
        dots: true,
        infinite: false,
    });

    $('.right-block.collection').slick({
        arrows: false,
        infinite: false
    });

    // lightbox

    const slickTracks = document.querySelectorAll('.slick-track'),
          slides = document.querySelectorAll('.slide'),
          slidesCollection = document.querySelectorAll('.slide-collection');

    slickTracks.forEach(item => {
        item.classList.add('lightbox');
    })

    slides.forEach(item => {

        const path = item.querySelector('img').getAttribute('src');

        item.setAttribute('href', path);

        // item.addEventListener('click', () => {
        //     const lgPrev = document.querySelector('.lg-prev'),
        //           lgPNext = document.querySelector('.lg-next'),
        //           lgCurrent = document.querySelector('.lg-counter-current'),
        //           lgAll = document.querySelector('.lg-counter-all');

        //     if(lgPrev !== null) {
        //         lgPrev.addEventListener('click', () => {
        //             if(lgCurrent.innerHTML == lgAll.innerHTML) {
        //                 lgPrev.style.display = 'none';
        //                 console.log('prev')
        //             }
        //         })
        //     }
        // })
    })

    slidesCollection.forEach(item => {

        const path = item.querySelector('img').getAttribute('src');

        item.setAttribute('href', path);
    })

    slickTracks.forEach(item => {
        lightGallery(item);
    })
}

export default slider;